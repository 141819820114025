import React from "react";
import classNames from "classnames";

type TestimonialProps = {
  quote: string;
  author: string;
};

const Testimonial: React.FC<TestimonialProps> = ({ quote, author }) => {
  return (
    <div
      className={classNames(
        "p-4 my-4 border rounded-md italic bg-gray-100 max-w-prose",
      )}
    >
      <p className={classNames("text-gray-700")}>&quot;{quote}&quot;</p>
      <p className={classNames("text-right mr-2 mt-2 font-bold text-gray-900")}>
        -{author}
      </p>
    </div>
  );
};

export default Testimonial;
