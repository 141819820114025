import React from "react";

import AlertCircle from "../../public/assets/svg/alert-circle.svg";

import classnames from "classnames";
import Spinner from "../UIElements/Spinner";
import Bitcoin from "../../public/assets/svg/btc.svg";

export type CurrencyExchangeIndicatorProps = {
  unitPrice?: number;
  ETHExchangeRate?: number;
  BTCExchangeRate?: number;
  loading?: boolean;
  converterError?: boolean;
  unitPriceError?: boolean;
  isMobile?: boolean;
  multiple?: number;
  textPrepend?: string;
};

// TODO: Refactor `unitPrice` here to be "inputAmount", and remove "multiple"
// • this should just take an input eth amount and not be concerned w/ how much a VM costs
// • any multiple stuff should be handled elsewhere

const CurrencyExchangeIndicator: React.FunctionComponent<
  CurrencyExchangeIndicatorProps
> = ({
  unitPrice = 0,
  loading,
  converterError,
  isMobile,
  multiple = 1,
  textPrepend,
  BTCExchangeRate,
}) => {
  const formattedUSDAmount = `$${new Intl.NumberFormat(undefined, {
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(unitPrice * multiple)}`;

  const formattedBTCAmount = BTCExchangeRate
    ? ((multiple * unitPrice) / BTCExchangeRate).toPrecision(8)
    : "";

  return (
    <div className="flex justify-end items-end">
      <div className={classnames(isMobile ? "text-xsmall" : "text-medium")}>
        {converterError ? (
          <AlertCircle />
        ) : loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            {textPrepend}
            {formattedBTCAmount && (
              <>
                <Bitcoin className="inline mb-[2px] mr-[3px]" />
                {formattedBTCAmount} or{" "}
              </>
            )}
            {formattedUSDAmount}
          </>
        )}
      </div>
    </div>
  );
};

export default CurrencyExchangeIndicator;
