/*
 This is currently being used to mock NFT unit price and related properties until an API endpoint is in place
 from which we can retrieve this data. 
*/

const VM_UNIT_PRICE = 0.56;

type UnitPrice = {
  unitPrice: number;
  unitPriceError?: string;
};

const useUnitPrice = (): UnitPrice => ({
  unitPrice: VM_UNIT_PRICE,
  unitPriceError: undefined,
});

export default useUnitPrice;
