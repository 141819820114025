import React from "react";

import ReactPlayer from "react-player";

import CurrencyExchangeIndicator from "../CurrencyExchangeIndicator/CurrencyExchangeIndicator";

import classnames from "classnames";
import FormatMinerDuration from "../util/FormatMinerDuration";

type NFTCardProps = {
  NFTUrl: string;
  minerName: string;
  minerHashrate: number;
  singleMinerPrice: number;
  isMobile: boolean;
  minerDuration: number;
  minerAlgorithm: string;
};

const NFTCard = ({
  NFTUrl,
  minerName,
  minerHashrate,
  singleMinerPrice,
  isMobile,
  minerDuration,
  minerAlgorithm,
}: NFTCardProps) => {
  return (
    <div
      className={classnames(
        "shadow-lg border border-grayscale-200 rounded-2xl bg-white",
        isMobile ? "flex flex-row content-center justify-between p-2.5" : "p-6",
      )}
    >
      {/* w-[382px] h-[382px] */}
      <div
        className={classnames(
          "overflow-hidden text-center relative flex justify-center items-center",
          isMobile
            ? `flex shrink h-[150px] w-[150px] mr-[1rem] rounded-xl`
            : `flex shrink rounded-2xl`,
        )}
      >
        <div className=" bg-grayscale-200">
          <ReactPlayer url={NFTUrl} width="100%" height="100%" playing loop />
        </div>
      </div>

      <div
        className={classnames(
          isMobile
            ? "flex flex-col justify-between shrink w-[250px] pr-1.5 pt-1.5 pb-1.5"
            : "mt-6",
        )}
      >
        <div>
          <div className={classnames("font-bold", "text-medium")}>
            {minerName}
          </div>

          <div className={classnames("text-medium")}>
            {`Hashrate ${minerHashrate} TH/s`}
          </div>
          <div className={classnames("text-medium")}>
            {`Algorithm ${minerAlgorithm}`}
          </div>
          {
            <div className={classnames("text-medium")}>
              {`Contract Term ${FormatMinerDuration(minerDuration)}`}
            </div>
          }
        </div>

        <div
          className={classnames(
            // isMobile ? "-ml-1.5" : "w-4/12 mt-6 -ml-2", // add if w/ eth price
            "flex justify-start", // remove if with eth price
          )}
        >
          <CurrencyExchangeIndicator unitPrice={singleMinerPrice} />
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
