const FormatMinerDuration = (minerDuration: number) => {
  const EPOCH_TIME = {
    SECOND: 1,
    MINUTE: 60,
    HOUR: 3600,
    DAY: 86400,
    WEEK: 604800,
    MONTH: 2629743, // 30.436875 days
    YEAR: 31556926, // 365.2425 days
  };

  const formatContractLength = (
    minerDuration: number,
    firstInterval: string,
    secondInterval: string,
  ) => {
    // calculate the amount of firstIntervals that fit into minerDuration and round down
    const firstIntervalValue = Math.trunc(
      minerDuration /
        EPOCH_TIME[firstInterval.toUpperCase() as keyof typeof EPOCH_TIME],
    );
    // calculate the amount of secondIntervals that fit into the remainder of minerDuration and round down
    const secondIntervalValue = Math.trunc(
      (minerDuration %
        EPOCH_TIME[firstInterval.toUpperCase() as keyof typeof EPOCH_TIME]) /
        EPOCH_TIME[secondInterval.toUpperCase() as keyof typeof EPOCH_TIME],
    );
    return `${firstIntervalValue} ${firstInterval}s${
      secondIntervalValue != 0
        ? `, ${secondIntervalValue} ${secondInterval}s`
        : ``
    }`;
  };

  // if (minerDuration / EPOCH_TIME.YEAR > 1) {
  //   return formatContractLength(minerDuration, "year", "month");
  // }
  // if (minerDuration / EPOCH_TIME.MONTH > 1) {
  //   return formatContractLength(minerDuration, "month", "day");
  // }
  // if (minerDuration / EPOCH_TIME.WEEK > 1) {
  //   return formatContractLength(minerDuration, "week", "day");
  // }

  if (minerDuration / EPOCH_TIME.DAY > 1) {
    return formatContractLength(minerDuration, "day", "hour");
  }
  if (minerDuration / EPOCH_TIME.HOUR > 1) {
    return formatContractLength(minerDuration, "hour", "minute");
  }
  return formatContractLength(minerDuration, "minute", "second");
};

export default FormatMinerDuration;
