import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
// import tw from "twin.macro";
import { PlusIcon, MinusIcon } from "@heroicons/react/solid";
import InputTitleAndHelper, {
  InputTitleAndHelperProps,
} from "./InputTitleAndHelper";
import { HTMLInputProps } from "../../services/reactor/types";

const VMNumberInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  HTMLInputProps & InputTitleAndHelperProps
> = (props, ref) => {
  const {
    min = -Infinity,
    max = Infinity,
    disabled,
    title,
    helperText,
  } = props;
  if (Number(min) > Number(max))
    throw new RangeError(
      `Error in VMNumberInput props: min (${min}) can not be more than max (${max})`,
    );

  const [, setRandomValue] = useState<number>(Math.random());
  const rerender = () => setRandomValue(Math.random());

  const internalRef = useRef<HTMLInputElement>(null);
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => internalRef.current,
  );

  useEffect(() => {
    if (internalRef?.current && typeof props.value !== "number") {
      internalRef.current.value = "0";
      rerender();
    }
  }, [props.value]);

  const decrement = () => {
    if (disabled) return;
    internalRef?.current?.stepDown();
    internalRef?.current?.dispatchEvent(
      new Event("change", {
        bubbles: true,
      }),
    );
    rerender();
  };
  const increment = () => {
    if (disabled) return;
    internalRef?.current?.stepUp();
    internalRef?.current?.dispatchEvent(
      new Event("change", {
        bubbles: true,
      }),
    );
    rerender();
  };
  const atMin = () =>
    internalRef?.current
      ? internalRef?.current?.value <= internalRef?.current?.min
      : false;
  const atMax = () => {
    return internalRef.current
      ? Number(internalRef?.current?.value) >= Number(internalRef?.current?.max)
      : false;
  };

  return (
    <InputTitleAndHelper title={title} helperText={helperText}>
      <div className={"relative"}>
        <input
          id={"number-chooser"}
          type={"number"}
          min={min}
          max={max}
          className={classNames(
            "w-full h-14 relative text-center rounded-xl box-border border border-grayscale-300 focus:border-primary-blue-500 focus:outline-none hide-spinners text-xlarge font-bold text-primary-blue-500",
            disabled && "text-primary-blue-200",
          )}
          style={{
            MozAppearance: "textfield",
          }}
          ref={internalRef}
          {...(typeof props.value === "number" ? { value: props.value } : {})}
          {...(props.onChange ? { onChange: props.onChange } : {})}
          onClick={(_e) => {
            internalRef?.current?.select();
            // if click is not on a plus or a minus, select the text in the field
          }}
        />
        <MinusIcon
          className={classNames(
            "absolute top-[19px] left-6 w-5 h-5 z-10 text-primary-blue-500",
            !atMin() && !disabled && "cursor-pointer",
            (atMin() || disabled) && "text-primary-blue-200",
          )}
          onClick={(_e) => {
            decrement();
            // e.stopPropagation();
          }}
        />
        <PlusIcon
          className={classNames(
            "absolute top-[19px] right-6 w-5 h-5 z-10 text-primary-blue-500",
            !atMax() && !disabled && "cursor-pointer",
            (atMax() || disabled) && "text-primary-blue-200",
          )}
          onClick={(_e) => {
            increment();
            // trying to stop the text from selecting if you click multiple times quickly
            // might have to move the buttons outside of the actual <input>
            // TODO: address this tiny issue (the selecting)
            // e.stopPropagation();
          }}
        />
      </div>
    </InputTitleAndHelper>
  );
};

export default React.forwardRef(VMNumberInput);
