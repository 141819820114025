import classNames from "classnames";
import React, { Suspense, lazy, useState } from "react";
import Button from "../Button/Button";
import { useWallet } from "../WalletProvider";
import {
  useFeedbackMessagesFunctions,
  FeedbackMessagesManager,
} from "./FeedbackMessagesManager";
import { getSigningUrl } from "../../services/reactor/api";
import Spinner from "../UIElements/Spinner";
import Dialog from "../Dialog/Dialog";
import { ModalProps } from "../../services/reactor/types";
import NavierLogoFull from "../../public/assets/svg/reactor-xyz-logo-rbg.svg";
import { useLayout } from "../BaseLayout/BaseLayout";

const HelloSignEmbedded = lazy(
  () => import("../../components/HelloSign/HelloSignEmbedded"), // eslint-disable-line
);

const HellosignModal: React.FunctionComponent<ModalProps> = (props) => {
  const { modalOpen, onCloseModal } = props;

  const { jwtToken } = useWallet();
  const { addMessage, messages, removeMessages } =
    useFeedbackMessagesFunctions();
  const { setBlur } = useLayout();

  setBlur();

  const [load, setLoad] = useState(false);

  const [hellosignUrl, setHellosignUrl] = useState<URL>();

  const getUrl = async () => {
    setLoad(true);
    getSigningUrl({ jwtToken })
      .then((e) => {
        if (e.url) {
          setHellosignUrl(e.url);
          console.log("Set hellosign url to: ", e.url);
        }
      })
      .catch((e) => {
        addMessage({
          msg: e.message ? e.message : "Fetching Hellosign Url Failed",
          status: "failed",
        });
        setLoad(false);
      });
  };

  const openHellosign = () => {
    setLoad(true);
    getUrl();
    addMessage({
      msg: "Confirming document signature",
      status: "pending",
      autoClear: 0,
    });
  };

  return (
    <Dialog
      dialogOpen={modalOpen}
      onClose={() => {
        if (!load) {
          onCloseModal();
          setLoad(false);
          removeMessages();
        }
      }}
      className={classNames(
        "sm:w-[450px]",
        "text-center",
        "px-8 py-6 bg-white rounded-lg shadow-lg text-secondary-black-500 border border-grayscale-200 rounded-2xl",
      )}
    >
      {hellosignUrl && (
        <Suspense fallback={null}>
          <HelloSignEmbedded url={hellosignUrl} />
        </Suspense>
      )}
      <div className={classNames("flex justify-center")}>
        <div>
          <div className="sm:w-[400px] mb-4">
            <div className="flex flex-col items-center mb-2">
              <NavierLogoFull className="mb-2" />
              <h5>Documentation not Signed</h5>
            </div>
            <div className="mt-4">
              It looks like you haven&apos;t signed our purchase agreement yet.
              In order to complete your purchase, please do so by clicking the
              button below.
            </div>
          </div>
          <div className="flex justify-center items-center gap-4">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                onCloseModal();
              }}
              disabled={load}
            >
              Close
            </Button>
            <Button
              className={"w-full"}
              variant="primary"
              onClick={openHellosign}
              disabled={load}
            >
              {load ? <Spinner /> : "Sign Document"}
            </Button>
          </div>
          {messages.length > 0 && (
            <div className="mt-3">
              <FeedbackMessagesManager />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default HellosignModal;
